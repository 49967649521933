<template>
  <Navigation isRegistration />
  <div class="password">
    <div class="password__column">
      <h1 class="password__column__header">
        {{ $t('reset-password-title') }}
      </h1>
      <p class="password__column__text">{{ $t('reset-password-text') }}</p>
      <el-form label-position="top" :model="$data" :rules="$options.rules">
        <el-form-item
          :label="$t('label.email')"
          prop="email"
          @change="validateEmail"
          :class="{ password__column__error: error.email }"
        >
          <el-input v-model="email" :placeholder="$t('label.email')" />
          <p v-if="error.email" class="password__column__error__text">
            {{ $t('error.email') }}
          </p>
        </el-form-item>
        <vue-recaptcha
          :siteKey="recaptchaSiteKey"
          :hl="currentLanguage"
          @verify="recaptcha = true"
          @expire="recaptchaExpired"
          ref="vueRecaptcha"
        />
      </el-form>
      <p class="password__column__message" v-if="userResetPasswordError">
        {{ $t('error.password-reset') }}
      </p>
      <Button
        class="button--right"
        :label="$t('button.reset-password')"
        :disabled="isDisabled"
        @click="resetPassword"
      ><IconArrowRight />
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vueRecaptcha from 'vue3-recaptcha2'

import Button from '@/components/Button.vue'
import Navigation from '@/components/Navigation.vue'
import IconArrowRight from '@/components/icons/IconArrowRight'

export default {
  components: {
    vueRecaptcha,
    Button,
    Navigation,
    IconArrowRight,
  },
  rules: {
    email: [
      {
        required: true,
        message: 'Trage deine E-Mail-Adresse hier ein.', // TODO: Might need translation
        trigger: 'blur',
      },
    ],
  },
  data() {
    return {
      email: '',
      recaptcha: false,
      error: {
        email: false,
      },
    }
  },
  mounted() {
    // reset store
    this.$store.commit('setUserResetPassword', false)
    this.$store.commit('setUserResetPasswordError', false)
  },
  computed: {
    ...mapGetters([
      'currentLanguage',
      'userResetPassword',
      'userResetPasswordError',
    ]),
    isDisabled() {
      return !this.email || this.error.email || !this.recaptcha
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  watch: {
    userResetPassword() {
      if (this.userResetPassword) this.$router.push({ path: '/' })
    },
    $data: {
      handler() {
        if (this.userResetPasswordError)
          this.$store.commit('setUserResetPasswordError', false)
      },
      deep: true,
    },
  },
  methods: {
    validateEmail() {
      if (this.email === '') {
        this.error.email = false
      } else if (
        (this.email && !this.email.includes('@')) ||
        !this.email.includes('.')
      ) {
        this.error.email = true
      }
    },
    resetPassword() {
      this.$store.dispatch('userResetPassword', this.email)
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
      this.recaptcha = false
    },
  },
}
</script>

<style lang="scss" scoped>
.password {
  @include flex-center-align;
  flex-direction: column;
  padding-top: 80px;

  &__column {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    max-width: 650px;
    width: 100%;

    @include mq($from: mobile) {
      padding: 80px 20px;
    }

    &__header {
      margin-bottom: 40px;

      &::after {
        content: '';
        background-color: $color-primary;
        display: block;
        margin-top: 7px;
        height: 4px;
        width: 80px;
      }
    }

    &__text {
      margin-bottom: 40px;
    }

    &__error {
      input {
        border-color: $color-error;
      }

      &__text {
        color: $color-error;
        font-size: 14px;
        line-height: 14px;
        padding-top: 4px;
      }
    }

    &__message {
      color: $color-error;
      margin-top: 20px;
    }

    .button--right {
      margin-top: 24px;
      align-self: flex-end;
    }
  }
}
</style>
